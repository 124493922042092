import pino, { stdSerializers } from 'pino'
import { lambdaRequestTracker, pinoLambdaDestination, StructuredLogFormatter } from 'pino-lambda'

const destination = pinoLambdaDestination({
  formatter: new StructuredLogFormatter()
})

export const logger = pino(
  {
    redact: [
      '*.Authorization',
      '*.*.Authorization',
      '*.*.*.Authorization',
      '*.authorization',
      '*.*.authorization',
      '*.*.*.authorization'
    ],
    serializers: {
      ...stdSerializers,
      errs: (errors) => errors.map(stdSerializers.err)
    }
  },
  destination
)

export const withRequest = lambdaRequestTracker({
  requestMixin: (event, context) => {
    return {
      functionName: context.functionName,
      functionVersion: context.functionVersion,
      functionRegion: process.env.AWS_REGION,
      functionServiceName: process.env.SERVICE_NAME,
      userId: event?.identity?.claims?.preferred_username || event?.identity?.claims?.sub
    }
  }
})
